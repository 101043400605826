import React from 'react';
import { css } from '../../../styled-system/css';
import { Typography } from './Typography';
import Pargh from './Pargh';

interface ContentBlockProps {
  title: string;
  paragraphFile: string;
  titleLevel?: 'h2' | 'h3'; // Allow different title levels if needed
}

const ContentBlock: React.FC<ContentBlockProps> = ({ title, paragraphFile, titleLevel = 'h2' }) => {
  return (
    <div
      className={css({
        marginTop: '0.75rem',
        width: '100%',
        padding: '0',
      })}
    >
      <Typography
        className={css({
          width: '100%',
          fontFamily: 'assistant',
          fontWeight: '600',
          margin: '2rem 1rem 1rem',
          '@media (max-width: 768px)': {
            fontSize: '1.3rem',
            textAlign: 'center',
            padding: '0 10px',
            margin: '1rem 0 0'
          },
        })}
        as={titleLevel}
      >
        {title}
      </Typography>
      <Pargh
        className={css({
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          textAlign: 'left',
          fontSize: '1.2rem',
          margin: '1rem',
          '@media (max-width: 768px)': {
            width: '90%',
            margin: 'auto auto 1rem',
            textAlign: 'left',
            fontSize: '1rem',
          },
        })}
        file={paragraphFile}
      ></Pargh>
    </div>
  );
};

export default ContentBlock;
